export default {
  name: "LearnWarrants",
  mounted() {
    var _this = this
    this.$nextTick(function() {
      $(".learnwarrants_select ul li").click(function() {
        var index = $(".learnwarrants_select ul li").index($(this));
        $(this).addClass("learnwarrants_select_choice_li");
        $(this)
          .siblings("li")
          .removeClass("learnwarrants_select_choice_li");
        $(".learnwarrants_content .ul_one .li_one")
          .eq(index)
          .show();
        $(".learnwarrants_content .ul_one .li_one")
          .eq(index)
          .siblings(".li_one")
          .hide();
        $(this).removeClass("learnwarrants_select_suspension_li");
        _this.$js.SetAnchor($('.learnwarrants_content').find('.ul_one').offset().top)
      });
      $(".issue").click(function() {
        $(this)
          .parent(".li_two")
          .find(".answer")
          .slideToggle("slow");
      });
    });
  },
  methods: {
    issueSkip: function(i, i2) {
      $(".learnwarrants_select ul li")
        .eq(i)
        .addClass("learnwarrants_select_choice_li");
      $(".learnwarrants_select ul li")
        .eq(i)
        .siblings("li")
        .removeClass("learnwarrants_select_choice_li");
      $(".learnwarrants_content .ul_one .li_one")
        .eq(i)
        .show();
      $(".learnwarrants_content .ul_one .li_one")
        .eq(i)
        .siblings(".li_one")
        .hide();
      $(".learnwarrants_select ul li")
        .eq(i)
        .removeClass("learnwarrants_select_suspension_li");
      this.$js.SetAnchor($('.learnwarrants_content').find('.ul_one').offset().top)
      if (i2 !== undefined) {
        $(".learnwarrants_content .ul_one .li_one")
          .eq(i)
          .children()
          .find(".li_two")
          .eq(i2)
          .find(".answer")
          .slideDown("slow");
      }
    },
    locatiion: function(i, i1, i2) {
      $(".learnwarrants_content .ul_one .li_one")
        .eq(i)
        .children()
        .find(".li_two")
        .eq(i1)
        .find(".answer")
        .slideUp("slow");
      $(".learnwarrants_content .ul_one .li_one")
        .eq(i)
        .children()
        .find(".li_two")
        .eq(i2)
        .find(".answer")
        .slideDown("slow");
    }
  }
};
